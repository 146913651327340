import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useApp } from "../../AppContext";
import { PropTypes } from "prop-types";

const Sidebar = (props) => {
  const [collapseOpen, setCollapseOpen] = useState();
  const { t } = useTranslation();
  const history = useHistory();
  const { balance, refetchBalance } = useApp();
  const location = useLocation();
  const selectedLanguage = localStorage.getItem("i18nextLng");
  // toggles collapse between opened and closed (true/false)
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };
  // closes the collapse
  const closeCollapse = () => {
    setCollapseOpen(false);
  };
  // creates the links that appear in the left menu / Sidebar
  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      return (
        <li className="nav-item" key={key}>
          {prop.ignore ? null : (
            <Link
              to={prop.path}
              onClick={closeCollapse}
              className={`nav-link
              ${
                window.location.pathname.indexOf(prop.path) !== -1
                  ? " text-simbalotto-orange active"
                  : ""
              }
              `}
            >
              <i className={prop.icon} />
              {t(prop.name)}
            </Link>
          )}
        </li>
      );
    });
  };

  const { routes, logo } = props;
  let logoLink;
  if (logo && logo.innerLink) {
    logoLink = logo.innerLink;
  } else if (logo && logo.outterLink) {
    logoLink = logo.outterLink;
  }

  async function logout() {
    fetch("/cookies/clear-logout", {
      method: "GET",
    }).then((response) => {
      if (response.status === 200) {
        history.push("/login");
      }
    });
  }

  useEffect(() => {
    refetchBalance();
  }, [location, refetchBalance]);

  return (
    <nav
      className="navbar-vertical fixed-left navbar-light bg-white opacity-9 navbar navbar-expand-md"
      id="sidenav-main"
    >
      <div className="container-fluid">
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleCollapse}
        >
          <i className="fa-solid fa-bars text-dark"></i>
        </button>
        {logo ? (
          <Link to={logoLink} className="pt-0 navbar-brand">
            <img
              alt={logo.imgAlt}
              className="navbar-brand-img"
              src={logo.imgSrc}
            />
          </Link>
        ) : null}

        <ul className="align-items-center d-md-none nav">
          <div className="dropdown">
            <div
              role="button"
              id="userDropDown"
              data-toggle="dropdown"
              aria-expanded="false"
            >
              <span className="avatar avatar-sm rounded-circle bg-simbalotto-orange text-simbalotto-green">
                <i className="fas fa-user" />
              </span>
            </div>

            <ul
              className="dropdown-menu dropdown-menu-right"
              aria-labelledby="userDropDown"
            >
              <li>
                <button className="dropdown-item" role="menuitem" type="button">
                  <i className="fas fa-wallet" />
                  <span>
                    {balance.toLocaleString("de-DE", {
                      style: "currency",
                      currency: "EUR",
                      minimumFractionDigits: 2,
                    })}
                  </span>
                </button>
              </li>
              <li>
                <Link className="dropdown-item" to="/my-profile">
                  <i className="fas fa-user" />
                  <span>{t("menu.my-profile")}</span>
                </Link>
              </li>
              <li>
                <button className="dropdown-item" onClick={() => logout()}>
                  <i className="fas fa-person-running" />
                  <span>{t("menu.logout")}</span>
                </button>
              </li>
            </ul>
          </div>
        </ul>

        <div
          className={`bg-white opacity-9 collapse navbar-collapse ${
            collapseOpen ? "show" : ""
          }`}
        >
          <div className="navbar-collapse-header d-md-none">
            <div className="row">
              {logo ? (
                <div className="col-6 collapse-brand">
                  {logo.innerLink ? (
                    <Link to={logo.innerLink}>
                      <img alt={logo.imgAlt} src={logo.imgSrc} />
                    </Link>
                  ) : (
                    <a href={logo.outterLink}>
                      <img alt={logo.imgAlt} src={logo.imgSrc} />
                    </a>
                  )}
                </div>
              ) : null}
              <div className="collapse-close col-6">
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={toggleCollapse}
                >
                  <span />
                  <span />
                </button>
              </div>
            </div>
          </div>
          <nav className="navbar-nav">
            <li className="nav-item">
              <a
                href={`https://jackpotlotteryonline.com/${selectedLanguage}/`}
                className="nav-link"
              >
                <i className="fas fa-baby-carriage" />
                {t("menu.play")}
              </a>
            </li>
            {createLinks(routes)}
            <li className="nav-item">
              <button className="btn nav-link" onClick={() => logout()}>
                <i className="fas fa-person-running" />
                {t("menu.logout")}
              </button>
            </li>
          </nav>
        </div>
      </div>
    </nav>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default Sidebar;
